import React, { useState, useEffect, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import {
  Container,
  Form,
  Button,
  Alert,
  Spinner,
  Modal,
  FormControl,
  Badge,
  CloseButton,
  ListGroup,
  Accordion,
} from "react-bootstrap";
import { QuillEditor } from "../../QuillEditor/QuillEditor"; // Import the QuillEditor component
import axios from "axios";
import { Helmet } from "react-helmet";
import "./EditPendingBlog.css";
import { useNavigate } from "react-router-dom";
import blogCategory from "../../../utils/blogCategory.json";
import blogTags from "../../../utils/blogTags.json";
import TinymceEditor from "../../../utils/TinymceEditor";

import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import FileUpload from "utils/FileUpload";

import { AuthContext } from "contexts/AuthContext";
import PreLoader from "utils/PreLoader";
import { toast, ToastContainer } from "react-toastify";


const editorConfiguration = {
  toolbar: [
    "undo",
    "redo",
    "|",
    "bold",
    "italic",
    {
      label: "Text style",
      icon: "text",
      items: [
        "heading",
        "underline",
        "fontSize",
        "highlight",
        "fontBackgroundColor",
        "fontColor",
        "fontFamily",
      ],
    },
    "link",
    "code",
    "codeBlock",
    "blockQuote",
    "|",
    {
      label: "List styles",
      icon: "alignLeft",
      items: [
        "bulletedList",
        "numberedList",
        "outdent",
        "indent",
        "alignment",
        "todoList",
      ],
    },
    {
      label: "Add media",
      icon: "plus",
      items: ["imageUpload", "imageInsert", "mediaEmbed"],
    },
    "|",
    "insertTable",
    "horizontalLine",
    "findAndReplace",
    "selectAll",
    "htmlEmbed",
    "pageBreak",
    "removeFormat",
    "sourceEditing",
    "specialCharacters",
    "strikethrough",
    "subscript",
    "superscript",
    "showBlocks",
    "style",
  ],
};

const EditPendingBlog = () => {
  const { user, loading, setLoading, logout } = useContext(AuthContext);
  const { id } = useParams();
  // const [blog, setBlog] = useState(null);
  const [title, setTitle] = useState("");
  const [authorDetails, setAuthorDetails] = useState(null);
  const [content, setContent] = useState("");
  const [category, setCategory] = useState("");
  const [slug, setSlug] = useState("");
  const [lastUpdatedAt, setLastUpdatedAt] = useState("");
  const [alert, setAlert] = useState(null);
  // const [loading, setloading] = useState(true);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSaveBlogModal, setShowSaveBlogModal] = useState(false);
  const [showDiscardBlogModal, setShowDiscardBlogModal] = useState(false);

  const [feedback, setFeedback] = useState("");
  const [feedbackComments, setFeedbackComments] = useState("");
  const [otherCategory, setOtherCategory] = useState(null);
  const [selectedTag, setSelectedTag] = useState("");
  const [tags, setTags] = useState([]);
  const [contentSize, setContentSize] = useState(0);
  const [rating, setRating] = useState(0);
  const [reviewRemarks, setReviewRemarks] = useState("");

  const [showPreviewBlogModal, setShowPreviewBlogModal] = useState(false);
  const [searchTitleResults, setTitleSearchResults] = useState([]);
  // const [loading, setLoading] = useState(false);

  const [initialContent, setInitialContent] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(`/api/reviewer/blog/editblog/${id}?userId=${user._id}&role=${user.role}`);
        const {
          slug,
          title,
          feedbackToAuthor,
          content,
          authorDetails,
          category,
          lastUpdatedAt,
          tags,
        } = response.data;
        setSlug(slug);
        setTitle(title);
        setAuthorDetails(authorDetails);
        setContent(content);
        setInitialContent(content);
        setCategory(category);
        setLastUpdatedAt(lastUpdatedAt);
        setFeedbackComments(feedbackToAuthor);
        setTags(tags);
        // setBlog(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching Blog:", error);
      }
    };

    fetchBlog();
  }, [id, navigate]);

  //  Size of the content
  useEffect(() => {
    const findContentSize = (content) => {
      setContentSize(
        (new TextEncoder().encode(content).length / 1024).toFixed(4)
      );
    };
    findContentSize(content);
  }, [content]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (rating === 0 || reviewRemarks === "") {
      toast.warning("Please give the rating and remarks.");
      return;
    }

    try {
      const response = await axios.put(
        `/api/reviewer/blog/editblog/save/${id}?userId=${user._id}&role=${user.role}&email=${user.email}`,
        {
          slug,
          title,
          content,
          category: category === "Other" ? otherCategory : category,
          rating: rating,
          reviewRemarks,
          tags,
        }
      );
      console.log(response.data);
      // Handle success or redirect to a different page
      // Show success alert
      toast.success("blog updated successfully");

      // Redirect to the homepage
      setTimeout(() => {
        navigate("/");
      }, 2000);
    } catch (error) {
      console.error("Error updating blog:", error);
      // Handle error
      // Show error alert
      toast.error("Failed to update blog");
    }
  };

  const handleSaveAsDraft = async () => {
    try {
      const response = await axios.post(`/api/blogs/saveasdraft?userId=${user._id}&role=${user.role}`, {
        id,
        slug,
        title,
        content,
        category: category === "Other" ? otherCategory : category,
        tags,
      });
      console.log(response.data);

      toast.success("blog saved successfully");

      // Redirect to the homepage
      setTimeout(() => {
        navigate("/");
      }, 2000);
    } catch (error) {
      toast.error("error occurred...");
      console.error("Error saving blog:", error);
      // Handle error
    }
  };

  const handleSelectedTag = (e) => {
    setSelectedTag(e.target.value);
  };

  const handleTagDismiss = (tag) => {
    const updatedTags = tags.filter((t) => t !== tag);
    setTags(updatedTags);
  };

  const handleTagAdd = () => {
    if (selectedTag !== "" && !tags.includes(selectedTag)) {
      setTags([...tags, selectedTag]);
      setSelectedTag("");
    }
  };

  if (loading) {
    return (
      <PreLoader isLoading={loading} />
    );
  }

  const slugify = (title) => {
    return title
      .toLowerCase()
      .replace(/[^a-zA-Z0-9 ]/g, "")
      .replace(/\s+/g, "-");
  };

  const handleshowSaveBlogModal = () => {
    setRating(0);
    setReviewRemarks("");
    setShowSaveBlogModal(true); // Show the confirmation modal
  };

  const handleshowDiscardBlogModal = () => {
    setRating(0);
    setReviewRemarks("");
    setShowDiscardBlogModal(true); // Show the confirmation modal
  };

  const handleshowAwaitingAuthorModal = () => {
    setShowConfirmModal(true); // Show the confirmation modal
  };

  const handleFeedbackToAuthor = () => {
    axios
      .post(`/api/reviewer/feedbacktoauthor?userId=${user._id}&role=${user.role}&email=${user.email}`, { feedback, id })
      .then((response) => {
        console.log(response.data.message);
        toast.success("Feedback shared with the author..");
        setShowConfirmModal(false);

        // Perform any additional cleanup or actions
        setTimeout(() => {
          navigate("/");
        }, 1000); // Navigate to the home page after 2 seconds
      })
      .catch((error) => {
        console.error("Error sending feedback to author:", error);
        toast.error("Error sending feedback to author:", error);
        // Handle any errors
        setShowConfirmModal(false);
      });
  };

  const handleDiscardBlog = async () => {
    if (rating === 0 || reviewRemarks === "") {
      toast.warning("Please give the rating and remarks before discarding.");
      return;
    }
    // const confirmDiscard = window.confirm(
    //   "Are you sure you want to discard this blog?"
    // );
    // if (discardRemarks!==null && discardRemarks!=="") {
    try {
      const response = await axios.post(`/api/reviewer/discardqueue/${id}?userId=${user._id}&role=${user.role}&email=${user.email}`, {
        rating,
        reviewRemarks,
      });
      toast.error(response.data.message);
      setTimeout(() => {
        navigate("/");
      }, 1500);
    } catch (error) {
      console.error("Error discarding blog:", error);
      toast.error(error.response.data.message);
    }
  };

  const handleRatingClick = (value) => {
    setRating(value);
  };

  async function searchSimilarTitles(searchQuery) {
    try {
      setLoading(true);
      const response = await axios.get(`/api/blogs/searchblogs/${searchQuery}`);
      console.log(response.data);
      setTitleSearchResults(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error searching similar blogs:", error);
      setLoading(false);
    }
  }

  return (
    <section className="newpage-section">
      <Helmet>
        <title>Edit Pending Blog - BloggerSpace Reviewer Panel</title>
      </Helmet>
      <Container>
        <h2 className="page-title">Edit Blog - Under Review</h2>
        <div className="heading-underline"></div>
        <ToastContainer />
        <p>
          <i>Last updated: {lastUpdatedAt?.slice(0, 19)}</i>
        </p>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="blogTitle" className="editblogfields">
            <Form.Label>Title: </Form.Label>
            <Form.Control
              type="text"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
                setSlug(slugify(e.target.value));
                searchSimilarTitles(e.target.value);
              }}
              placeholder="Enter blog title"
            />
          </Form.Group>

          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                Published Blogs with Similar title:
              </Accordion.Header>
              <Accordion.Body>
                <ListGroup>
                  {searchTitleResults.map((blog) => (
                    <ListGroup.Item key={blog._id} className="">
                      <Link
                        to={`/${blog.slug}`}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        <h6>{blog.title}</h6>
                      </Link>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <Form.Group controlId="blogAuthor" className="editblogfields">
            <Form.Label>Author:</Form.Label>
            <Form.Control
              type="text"
              value={authorDetails?.userName}
              // onChange={(e) => setAuthor(e.target.value)}
              placeholder="Enter author name"
              disabled
            />
          </Form.Group>

          <Form.Group controlId="blogCategory" className="editblogfields">
            <Form.Label>Category:</Form.Label>
            <Form.Control
              as="select"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              placeholder="Select category"
              required
            >
              <option value="">Select Category</option>
              {blogCategory.map((category) => (
                <option key={category.value} value={category.value}>
                  {category.label}
                </option>
              ))}
              {/* <option value="">Select Category</option>
            <option value="Web development">Web development</option>
            <option value="Technology">Technology</option>
            <option value="Travel">Travel</option>
            <option value="Food">Food</option> */}
              {/* Add more category options as needed */}
            </Form.Control>
          </Form.Group>

          {category === "Other" ? (
            <Form.Group controlId="otherCategory" className="newblogfields">
              <Form.Label>Specify other category:</Form.Label>
              <Form.Control
                type="text"
                value={otherCategory}
                onChange={(e) => {
                  setOtherCategory(e.target.value);
                }}
                placeholder="Enter category"
                required
              />
            </Form.Group>
          ) : null}

          <Form.Group controlId="blogCategory" className="newblogfields">
            <Form.Label>Tags:</Form.Label>
            <Form.Control
              as="select"
              value={selectedTag}
              onChange={handleSelectedTag}
              placeholder="Select tag"
            >
              <option value="">Select Tag</option>
              {blogTags.map((tag) => (
                <option key={tag} value={tag}>
                  {tag}
                </option>
              ))}
            </Form.Control>
            <Button
              variant="success"
              size="sm"
              onClick={handleTagAdd}
              className="my-2"
            >
              Add Tag
            </Button>
            <br />
            {tags.map((tag) => (
              <Badge key={tag} pill bg="secondary">
                {tag}
                <CloseButton
                  variant="white"
                  onClick={() => handleTagDismiss(tag)}
                ></CloseButton>
              </Badge>
            ))}
          </Form.Group>

          <Form.Group controlId="blogContent" className="editblogfields">
            <Form.Label>Content:</Form.Label>
            {/* <QuillEditor content={content} onContentChange={setContent} /> */}
            {/* <TinymceEditor content={content} onContentChange={setContent} initialValue={initialContent} /> */}

            <CKEditor
            is
              editor={Editor}
              config={editorConfiguration}
              data={initialContent}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                // console.log( 'Editor is ready to use!', editor );
                console.log("Editor is ready to use!");
              }}
              onChange={(event, editor) => {
                setContent(editor.getData());
                // console.log(event);
                // console.log(editor.getData());
              }}
              onBlur={(event, editor) => {
                console.log("Blur.");
                // console.log( 'Blur.', editor );
              }}
              onFocus={(event, editor) => {
                console.log("Focus.");
                // console.log( 'Focus.', editor );
              }}
            />

              <FileUpload />

          </Form.Group>

          <h6>Content size: {contentSize} KB</h6>

          <h6>Feedbacks:</h6>
          <ul>
            {feedbackComments.length!==0 && feedbackComments?.map((comment, index) => (
              <li key={index}>
                <text className="comment-content">{comment.Feedback}</text>
                <br />
                <i className="comment-user">
                  <strong>Feedback by:</strong> {comment.ReviewerEmail}
                  {" - "}
                  {comment.LastUpdated}
                </i>{" "}
                <hr />
              </li>
            ))}
          </ul>

          <br />
          <Button
            variant="secondary"
            className="submit-editedblog"
            onClick={() => setShowPreviewBlogModal(true)}
          >
            Preview Blog
          </Button>
          <Button
            variant="primary"
            className="submit-editedblog"
            onClick={handleSaveAsDraft}
            disabled
          >
            Save as draft
          </Button>
          {/* <Button variant="primary" type="submit" className="submit-editedblog">
          Save
        </Button> */}
          <Button
            variant="primary"
            onClick={handleshowSaveBlogModal}
            className="submit-editedblog"
          >
            Submit
          </Button>
          <Button
            variant="primary"
            onClick={() => navigate(-1)}
            className="goback-editedblog"
          >
            Go Back
          </Button>
          <Button
            variant="primary"
            className="goback-editedblog"
            onClick={handleshowAwaitingAuthorModal}
          >
            Awaiting Author
          </Button>
          <Button
            variant="danger"
            className="goback-editedblog"
            onClick={handleshowDiscardBlogModal}
          >
            Discard
          </Button>
        </Form>

        <Modal
          show={showPreviewBlogModal}
          onHide={() => setShowPreviewBlogModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Preview Blog</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Title: {title}
            <br />
            Slug: {slug}
            <br />
            <br />
            Content: <br />
            <div dangerouslySetInnerHTML={{ __html: content }} />
            <h6>Content size: {contentSize} KB</h6>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowPreviewBlogModal(false)}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showSaveBlogModal}
          onHide={() => setShowSaveBlogModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Move to In-Review</Modal.Title>
          </Modal.Header>
          {alert && (
            <Alert
              variant={alert.type}
              onClose={() => setAlert(null)}
              dismissible
            >
              {alert.message}
            </Alert>
          )}
          <Modal.Body>
            <h6>Rating:</h6>
            <div className="star-container">
              {[1, 2, 3, 4, 5].map((value) => (
                <span
                  key={value}
                  className={`star ${value <= rating ? "filled" : ""}`}
                  onClick={() => handleRatingClick(value)}
                >
                  &#9733;
                </span>
              ))}
            </div>
            <FormControl
              type="text"
              placeholder="Remarks"
              value={reviewRemarks}
              onChange={(e) => setReviewRemarks(e.target.value)}
              required
            />
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowSaveBlogModal(false)}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSubmit}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showConfirmModal}
          onHide={() => setShowConfirmModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Feedback to author</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormControl
              type="text"
              placeholder="Type feedback to author"
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
            />
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowConfirmModal(false)}
            >
              Cancel
            </Button>
            <Button variant="warning" onClick={handleFeedbackToAuthor}>
              Submit Feedback
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showDiscardBlogModal}
          onHide={() => setShowDiscardBlogModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Move to Discard Queue</Modal.Title>
          </Modal.Header>
          {alert && (
            <Alert
              variant={alert.type}
              onClose={() => setAlert(null)}
              dismissible
            >
              {alert.message}
            </Alert>
          )}
          <Modal.Body>
            <h6>Rating:</h6>
            <div className="star-container">
              {[1, 2, 3, 4, 5].map((value) => (
                <span
                  key={value}
                  className={`star ${value <= rating ? "filled" : ""}`}
                  onClick={() => handleRatingClick(value)}
                >
                  &#9733;
                </span>
              ))}
            </div>
            <FormControl
              type="text"
              placeholder="Remarks"
              value={reviewRemarks}
              onChange={(e) => setReviewRemarks(e.target.value)}
              required
            />
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowDiscardBlogModal(false)}
            >
              Cancel
            </Button>
            <Button variant="danger" onClick={handleDiscardBlog}>
              Discard
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </section>
  );
};

export default EditPendingBlog;
