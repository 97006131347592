import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Alert,
  Card,
  Spinner,
  Form,
  FormControl,
  Button,
  ListGroup,
  Tab,
  Tabs,
  Badge,
  Accordion,
} from "react-bootstrap";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import "./AllUsers.css";

import { AuthContext } from "contexts/AuthContext";
import PreLoader from "utils/PreLoader";
import { toast, ToastContainer } from "react-toastify";

const AllUsers = () => {
  const { user, loading, logout } = useContext(AuthContext);
  const [allUsers, setAllUsers] = useState(null);
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        const response = await axios.get(`/api/admin/dashboard/allusers?userId=${user._id}&role=${user.role}`);
        setAllUsers(response.data);
      } catch (error) {
        console.error("Error fetching all users:", error);
      }
    };
    fetchAllUsers();
  }, []);

  const deleteUserAccount = async (id, email) => {
    try {
      const confirmDelete = window.confirm(
        "Are you sure you want to delete this user permanently.?\n" + "User email: " + email
      );
      if(confirmDelete){
        const response = await axios.delete(
          `/api/admin/dashboard/deleteuser/${id}?userId=${user._id}&role=${user.role}`,
          { email }
        );
        console.log(response.data);
        toast.success("User account deleted successfully");
      }
    } catch (error) {
      toast.error("Failed to delete user account");
      console.error("Failed to delete user account");
    }
  };

  let i = 0;

  return (
    <section className="newpage-section">
      <Helmet>
        <title>All Users - BloggerSpace Reviewer Panel</title>
      </Helmet>
      <Container>
        <h3 className="page-title">All Users Dashboard</h3>
        <div className="heading-underline"></div>

        <h5 className="mt-4">
          <b>All Users:</b>
        </h5>
        {allUsers === null ? (
              <PreLoader isLoading={true} />
            ) : allUsers?.length === 0 ? (
          <div>No verified reviewers found</div>
        ) : (
          <>
            <ListGroup>
              {allUsers?.map((user) => (
                <ListGroup.Item key={i}>
                  <Accordion>
                    <Accordion.Item eventKey={i}>
                      <Accordion.Header>
                        <b>
                          {++i}. {user.fullName}
                        </b>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="col-auto">
                          <ul>
                            <li>Name: {user.fullName}</li>
                            <li>Email: {user.email}</li>
                            <li>UserName: {user.userName}</li>
                            <li>
                              isVerified:{" "}
                              {user.isVerified ? "Verified" : "Not Verified"}
                            </li>
                            <li>CreatedAt: {user.createdAt}</li>
                          </ul>
                        </div>
                        <div className="col-auto">
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() =>
                              deleteUserAccount(user._id, user.email)
                            }
                          >
                            Delete Account
                          </Button>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </>
        )}
      </Container>
    </section>
  );
};

export default AllUsers;
