import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import AdminLoginScreen from "./components/LoginScreen/ReviewerLoginScreen.js";
import Header from "./components/Header/Header.js";
import SignupPage from "./components/SignUpPage/SignupPage.js";
import MyProfilePage from "./components/MyProfile/MyProfilePage.js";
import PendingReview from "./components/Blogs/PendingReviewBlogs/PendingReview.js";
import EditPendingBlog from "./components/Blogs/EditPendingBlog/EditPendingBlog.js";
import InReview from "./components/Blogs/InReviewBlogs/InReview.js";
import EditInReviewBlog from "./components/Blogs/EditInReviewBlog/EditInReviewBlog.js";
import ReviewedBlogs from "./components/Blogs/ReviewerReviewedBlogs/ReviewedBlogs.js";
import Settings from "./components/Settings/Settings.js";
import PublishedBlogs from "./components/Blogs/PublishedBlogs/PublishedBlogs.js";
import BlogAssignment from "./components/Blogs/BlogAssignment/BlogAssignment.js";
import Footer from "./components/Footer/Footer.js";
import ChangePasswordPage from "./components/PasswordPage/ChangePassword/ChangePasswordPage.js";
import ForgotPasswordPage from "./components/PasswordPage/ForgotPassword/ForgotPasswordPage.js";
import ResetPasswordPage from "./components/PasswordPage/ResetPassword/ResetPasswordPage.js";
import AboutDeveloper from "./components/AboutDeveloper/AboutDeveloper.js";
import AdminDashboard from "./components/Dashboard/AdminDashboard.js";
import AllReviewers from "./components/Dashboard/AllReviewers/AllReviewers.js";
import AllUsers from "./components/Dashboard/AllUsers/AllUsers.js";
import Helmet from "react-helmet";
// import Homepage from "./components/Home/HomePage.js";

import PrivacyPolicy from "./components/Footer/PrivacyPolicy/PrivacyPolicy.js";
import TermsAndConditions from "./components/Footer/TermsAndConditions/TermsAndConditions.js";
import AboutBloggerSpace from "./components/Footer/AboutBloggerSpace/AboutBloggerSpace.js";
import AllCommunityPosts from "./components/Community/AllCommunityPosts.js";
import AdminNewBlog from "components/Blogs/AdminBlogs/NewBlog/AdminNewBlog.js";
import AdminEditBlog from "components/Blogs/AdminBlogs/EditBlog/AdminEditBlog.js";
import AdminBlogs from "components/Blogs/AdminBlogs/AdminBlogs.js";
import Newsletter from "components/Announcements/Newsletter.js";
import DownloadReport from "components/Dashboard/Reports/DownloadReport.js";
import HomePage from "components/Home/HomePage.js";
import { AuthProvider } from "contexts/AuthContext.js";
import { ToastContainer } from "react-toastify";
import ProtectedRoute from "components/ProtectedRoute.js";

const App = () => {

  return (
    <AuthProvider>
      <BrowserRouter>
      {/* <ToastContainer /> */}
        <Header />

        <Helmet>
        <meta
          name="description"
          content="The Reviewer panel where reviewers need to review the assigned blogs and give the rating. The new reviewer requests would be sent to admin for approval. The admin has full access and can delete any user, revoke/grant reviewer access. Review stages: Pending for Review-Under review-In Review-Awaiting author (if need modification)-Publish. This website is developed by Teekam Singh"
        />

        <title>Reviewer Panel - BloggerSpace</title>

        <meta
          name="keywords"
          content="reviewerpanel, reviewblogs, reviewer, reviewbloggerspace, blogging, blogs, technicalblogs, teekam, singhteekam, singh_teekam, singh__teekam, bloggerspace, bloggingwebsite, articles "
        />
        <meta
          name="apple-mobile-web-app-title"
          content="BloggerSpace Reviewer Panel"
        />

        {/* Open Graph meta tags for social media sharing */}
        <meta property="og:title" content="BloggerSpace Reviewer Panel" />
        <meta
          property="og:description"
          content="The Reviewer panel where reviewers need to review the assigned blogs and give the rating. The new reviewer requests would be sent to admin for approval. The admin has full access and can delete any user, revoke/grant reviewer access. Review stages: Pending for Review-Under review-In Review-Awaiting author (if need modification)-Publish. This website is developed by Teekam Singh"
        />
        <meta property="og:image" content="%PUBLIC_URL%/BLOGGERSPACE.png" />
        <meta
          property="og:url"
          content={window.location.href}
        />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="BloggerSpace Reviewer Panel" />
        <meta
          name="twitter:description"
          content="The Reviewer panel where reviewers need to review the assigned blogs and give the rating. The new reviewer requests would be sent to admin for approval. The admin has full access and can delete any user, revoke/grant reviewer access. Review stages: Pending for Review-Under review-In Review-Awaiting author (if need modification)-Publish. This website is developed by Teekam Singh"
        />
        <meta name="twitter:image" content="%PUBLIC_URL%/BLOGGERSPACE.png" />
        <meta name="twitter:site" content="@singh__teekam" />

        <link
          rel="canonical"
          href={window.location.href}
        />
      </Helmet>


        <main>
          <Routes>
            <Route path="/" element={<HomePage />} exact />
            <Route path="/login" element={<AdminLoginScreen />} exact />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/myprofile" element={<ProtectedRoute><MyProfilePage /></ProtectedRoute>} />
            <Route path="/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
            <Route path="/changepassword" element={<ProtectedRoute><ChangePasswordPage /></ProtectedRoute>} />
            <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
            <Route path="/dashboard" element={<ProtectedRoute><AdminDashboard /></ProtectedRoute>} />
            <Route path="/dashboard/allreviewers" element={<ProtectedRoute><AllReviewers /></ProtectedRoute>} />
            <Route path="/dashboard/allusers" element={<ProtectedRoute><AllUsers /></ProtectedRoute>} />
            <Route path="/dashboard/community" element={<ProtectedRoute><AllCommunityPosts /></ProtectedRoute>} />
            <Route path="/dashboard/reports" element={<ProtectedRoute><DownloadReport /></ProtectedRoute>} />

            <Route
              path="/resetpassword/:resetToken"
              element={<ResetPasswordPage />}
            />

            <Route path="/pendingreview" element={<ProtectedRoute><PendingReview /></ProtectedRoute>} />
            <Route
              path="/reviewer/blog/editblog/:id"
              element={<ProtectedRoute><EditPendingBlog /></ProtectedRoute>}
            />
            <Route path="/reviewedblogs" element={<ProtectedRoute><ReviewedBlogs /></ProtectedRoute>} />

            {/* Admin Route */}
            <Route path="/admin/inreview" element={<ProtectedRoute><InReview /></ProtectedRoute>} />
            <Route
              path="/admin/blog/editblog/:id"
              element={<ProtectedRoute><EditInReviewBlog /></ProtectedRoute>}
            />
            <Route path="/admin/published" element={<ProtectedRoute><PublishedBlogs /></ProtectedRoute>} />
            <Route path="/admin/assignblogs" element={<ProtectedRoute><BlogAssignment /></ProtectedRoute>} />
            <Route path="/aboutdeveloper" element={<AboutDeveloper />} />

            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/termsandconditions" element={<TermsAndConditions />} />
            <Route path="/about" element={<AboutBloggerSpace />} />


            <Route path="/admin/newblog" element={<ProtectedRoute><AdminNewBlog /></ProtectedRoute>} />
            <Route path="/admin/editblog/:id" element={<ProtectedRoute><AdminEditBlog /></ProtectedRoute>} />
            <Route path="/admin/myblogs" element={<ProtectedRoute><AdminBlogs /></ProtectedRoute>} />

            {/* Announcements */}
            <Route path="/admin/newsletter" element={<ProtectedRoute><Newsletter /></ProtectedRoute>} />
          </Routes>
        </main>
        <Footer />
      </BrowserRouter>
    </AuthProvider>
  );
};

export default App;
