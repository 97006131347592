import { CgNotes } from "react-icons/cg";
import { FaBook, FaGlobe, FaGlobeAsia, FaHome, FaKey, FaSave, FaSitemap } from "react-icons/fa";
import { IoLogIn, IoLogInOutline, IoPeople, IoPerson } from "react-icons/io5";
import { IoIosLogOut, IoIosSettings, IoMdSettings } from "react-icons/io";

export const headerLinks = [
    {
      icon: <FaHome />,
      name: "Home",
      to: "/",
      target: "",
      onclick: "closeCanvas",
    },
    {
      icon: <IoLogInOutline />,
      name: "Login",
      to: "/login",
      target: "",
      onclick: "closeCanvas",
    },
    {
      icon: <IoLogIn />,
      name: "Sign Up",
      to: "/signup",
      target: "",
      onclick: "closeCanvas",
    },
    // {
    //   icon: <CgNotes />,
    //   name: "Admin Blogs",
    //   to: "/adminblogs",
    //   target: "",
    //   onclick: "closeCanvas",
    // },
    // {
    //   icon: <IoPeople />,
    //   name: "Community",
    //   to: "/community",
    //   target: "",
    //   onclick: "closeCanvas",
    // },
    // {
    //   icon: <FaBook />,
    //   name: "Writing Guidelines",
    //   to: "/guidelines",
    //   target: "",
    //   onclick: "closeCanvas",
    // },
    {
      icon: <FaGlobeAsia />,
      name: "Writing Panel",
      to: "https://bloggerspace.singhteekam.in/",
      target: "_blank",
      onclick: "closeCanvas",
    },
    // {
    //   icon: <FaSitemap />,
    //   name: "Sitemap",
    //   to: "/sitemap",
    //   target: "",
    //   onclick: "closeCanvas",
    // },
    {
      icon: <IoPerson />,
      name: "About Developer",
      to: `${process.env.REACT_APP_BLOGGERSPACE}/aboutdeveloper`,
      target: "_blank",
      onclick: "closeCanvas",
    },
  ];

export const authReviewerLinks = [
    {
      icon: <FaHome />,
      name: "Home",
      to: "/",
      target: "",
      onclick: "closeCanvas",
    },
    {
      icon: <CgNotes />,
      name: "Pending Review Blogs",
      to: "/pendingreview",
      target: "",
      onclick: "closeCanvas",
    },
    {
      icon: <CgNotes />,
      name: "Reviewed Blogs",
      to: "/reviewedblogs",
      target: "",
      onclick: "closeCanvas",
    },
    {
      icon: <IoPerson />,
      name: "My Profile",
      to: "/myprofile",
      target: "",
      onclick: "closeCanvas",
    },
    {
      icon: <IoIosSettings />,
      name: "Settings",
      to: "/settings",
      target: "",
      onclick: "closeCanvas",
    },
    {
      icon: <FaKey />,
      name: "Change Password",
      to: "/changepassword",
      target: "",
      onclick: "closeCanvas",
    },
    {
      icon: <IoIosLogOut />,
      name: "Logout",
      to: "/",
      target: "",
      onclick: "logout",
    },
    {
      icon: <FaGlobeAsia />,
      name: "Writing Panel",
      to: "https://bloggerspace.singhteekam.in/",
      target: "_blank",
      onclick: "closeCanvas",
    },
    {
      icon: <IoPerson />,
      name: "About Developer",
      to: `${process.env.REACT_APP_BLOGGERSPACE}/aboutdeveloper`,
      target: "_blank",
      onclick: "closeCanvas",
    },
  ];

  export const authAdminLinks = [
    {
      icon: <FaHome />,
      name: "Home",
      to: "/",
      target: "",
      onclick: "closeCanvas",
    },
    {
      icon: <CgNotes />,
      name: "Dashboard",
      to: "/dashboard",
      target: "",
      onclick: "closeCanvas",
    },
    {
      icon: <CgNotes />,
      name: "Admin Blogs",
      to: "/admin/myblogs",
      target: "",
      onclick: "closeCanvas",
    },
    {
      icon: <CgNotes />,
      name: "New Blog",
      to: "/admin/newblog",
      target: "",
      onclick: "closeCanvas",
    },
    {
      icon: <CgNotes />,
      name: "Assign Blogs",
      to: "/admin/assignblogs",
      target: "",
      onclick: "closeCanvas",
    },
    {
      icon: <CgNotes />,
      name: "In Review Blogs",
      to: "/admin/inreview",
      target: "",
      onclick: "closeCanvas",
    },
    {
      icon: <CgNotes />,
      name: "Published Blogs",
      to: "/admin/published",
      target: "",
      onclick: "closeCanvas",
    },
    {
      icon: <IoPerson />,
      name: "My Profile",
      to: "/myprofile",
      target: "",
      onclick: "closeCanvas",
    },
    {
      icon: <IoIosSettings />,
      name: "Settings",
      to: "/settings",
      target: "",
      onclick: "closeCanvas",
    },
    {
      icon: <IoIosSettings />,
      name: "Newsletter",
      to: "/admin/newsletter",
      target: "",
      onclick: "closeCanvas",
    },
    {
      icon: <FaKey />,
      name: "Change Password",
      to: "/changepassword",
      target: "",
      onclick: "closeCanvas",
    },
    {
      icon: <IoIosLogOut />,
      name: "Logout",
      to: "/",
      target: "",
      onclick: "logout",
    }
  ];