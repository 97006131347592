import React, { useEffect, useState, useContext } from "react";
import { Form, Button, Alert, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./ChangePasswordPage.css";

import { AuthContext } from "contexts/AuthContext";
import PreLoader from "utils/PreLoader";
import { toast, ToastContainer } from "react-toastify";

function ChangePasswordPage() {
  const { user, loading,setLoading, logout } = useContext(AuthContext);
  // const [user, setUser] = useState(null);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

  const navigate = useNavigate();

  const handleOldPasswordChange = (e) => {
    setOldPassword(e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmNewPasswordChange = (e) => {
    setConfirmNewPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (newPassword !== confirmNewPassword) {
      toast.error("Passwords do not match");
      return;
    }
    setLoading(true);

    // Password complexity validation
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/;
    if (!passwordRegex.test(newPassword)) {
      toast.warning(
        "Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one digit, and one special character"
      );
      setLoading(false);
      return;
    }

    // Create the request body
    const requestBody = {
      oldPassword: oldPassword,
      newPassword: newPassword,
    };

    // Send the password reset request to the backend
    axios
      .post(`/api/reviewer/changepassword?userId=${user._id}&role=${user.role}`, requestBody)
      .then((response) => {
        toast.success("Password Changed successful");
        setLoading(false);

        setTimeout(() => {
          navigate(-1);
        }, 1000);
      })
      .catch((error) => {
        if (error.response) {
          // Request was made and server responded with a status code
          if (error.response.status === 401) {
            toast.error("Invalid old password");
          } else if (error.response.status === 500) {
            toast.error("Server error");
          } else {
            toast.error("Failed to change password");
          }
        } else if (error.request) {
          // Request was made but no response received
          toast.error("No response from server");
        } else {
          // Error occurred during the request setup
          toast.error("Error setting up the request");
        }
        setLoading(false);
      });
  };

  const toggleOldPasswordVisibility = () => {
    setShowOldPassword(!showOldPassword);
  };
  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };
  const toggleConfirmNewPasswordVisibility = () => {
    setShowConfirmNewPassword(!showConfirmNewPassword);
  };

  return (
    <section className="newpage-section">
      <Helmet>
        <title>Change Password - BloggerSpace Reviewer Panel</title>
      </Helmet>
      <Container>
        <ToastContainer />
        <div className="container col-lg-7">
          <div className="row justify-content-center">
            <div className="col-md-6 col-sm-8">
              <div className="password-change-form">
                <h2 className="text-center mb-4">Change Password</h2>
              
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="oldPassword">
                    <Form.Label>Old Password</Form.Label>
                    <div className="password-input">
                      <Form.Control
                        type={showOldPassword ? "text" : "password"}
                        placeholder="Enter old password"
                        value={oldPassword}
                        onChange={handleOldPasswordChange}
                        required
                      />
                      <i
                        className={`toggle-password fas ${
                          showOldPassword ? "fa-eye-slash" : "fa-eye"
                        }`}
                        onClick={toggleOldPasswordVisibility}
                      ></i>
                    </div>
                  </Form.Group>

                  <Form.Group controlId="newPassword">
                    <Form.Label>New Password</Form.Label>
                    <div className="password-input">
                      <Form.Control
                        type={showNewPassword ? "text" : "password"}
                        placeholder="Enter new password"
                        value={newPassword}
                        onChange={handleNewPasswordChange}
                        required
                      />
                      <i
                        className={`toggle-password fas ${
                          showNewPassword ? "fa-eye-slash" : "fa-eye"
                        }`}
                        onClick={toggleNewPasswordVisibility}
                      ></i>
                    </div>
                  </Form.Group>

                  <Form.Group controlId="confirmNewPassword">
                    <Form.Label>Confirm Password</Form.Label>
                    <div className="password-input">
                      <Form.Control
                        type={showConfirmNewPassword ? "text" : "password"}
                        placeholder="Confirm new password"
                        value={confirmNewPassword}
                        onChange={handleConfirmNewPasswordChange}
                        required
                      />
                      <i
                        className={`toggle-password fas ${
                          showConfirmNewPassword ? "fa-eye-slash" : "fa-eye"
                        }`}
                        onClick={toggleConfirmNewPasswordVisibility}
                      ></i>
                    </div>
                  </Form.Group>

                  <Button
                    variant="primary"
                    type="submit"
                    className="password-change-button"
                    block
                    disabled={loading}
                  >
                    {loading ? "Changing Password..." : "Change Password"}
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default ChangePasswordPage;
