import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Card,
  ListGroup,
  Spinner,
  Form,
  Button,
  Alert,
  Modal,
  Tab,
  Tabs,
  Badge,
} from "react-bootstrap";
import { Link, Navigate, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";

import { AuthContext } from "contexts/AuthContext";
import PreLoader from "utils/PreLoader";

const AdminBlogs = () => {
  const { user, loading, logout } = useContext(AuthContext);
  const [draftBlogs, setDraftBlogs] = useState(null);
  let i = 0,
    j = 0,
    k = 0;

  const [publishedBlogs, setPublishedBlogs] = useState(null);
  const [discardedBlogs, setDiscardedBlogs] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [previewBlog, setPreviewBlog] = useState(null);
  const [previewContentSize, setPreviewContentSize] = useState(0);

  const location= useLocation();

  //  Size of the content
  useEffect(() => {
    const findContentSize = (content) => {
      setPreviewContentSize(
        (new TextEncoder().encode(content).length / 1024).toFixed(4)
      );
    };
    findContentSize(previewBlog?.content);
  }, [previewBlog]);

  const fetchDraftBlogs = async () => {
    try {
      const response = await axios.get(
        `/api/admin/blogs/drafts?userId=${user._id}&role=${user.role}`
      );
      setDraftBlogs(response.data);
    } catch (error) {
      console.error("Error fetching pending blogs:", error);
    }
  };

  const fetchPublishedBlogs = async () => {
    try {
      const response = await axios.get("/api/admin/blogs/published");
      setPublishedBlogs(response.data);
    } catch (error) {
      console.error("Error fetching under review blogs:", error);
    }
  };

  const fetchDiscardedBlogs = async () => {
    try {
      const response = await axios.get(
        `/api/admin/blogs/discarded?userId=${user._id}&role=${user.role}`
      );
      setDiscardedBlogs(response.data);
    } catch (error) {
      console.error("Error fetching discard queue blogs:", error);
    }
  };

  useEffect(() => {
    fetchDraftBlogs();
    fetchPublishedBlogs();
    fetchDiscardedBlogs();
  }, [user]);

  const handleDiscardBlog = async (blogId, slug) => {
    const confirmDiscard = window.confirm(
      "Are you sure you want to discard this blog?\n" + "Title: " + slug
    );
    if (confirmDiscard) {
      try {
        // Handle the response
        toast.success("blog discarded successfully");
        fetchDraftBlogs();
        fetchPublishedBlogs();
        fetchDiscardedBlogs();
      } catch (error) {
        console.error("Error discarding blog:", error.response.data);
      }
    }
  };

  const handlePreviewBlogModal = async (id) => {
    try {
      const response = await axios.get(
        `/api/admin/blog/editblog/${id}?userId=${user._id}&role=${user.role}`
      );
      setPreviewBlog(response.data);
      setShowConfirmModal(true); // Show the confirmation modal
    } catch (error) {
      console.error("Error Fetching preview blog:", error.response.data);
    }
  };

  if (loading) {
    return <PreLoader isLoading={loading} />;
  }

  if(!user && !loading){
    logout();
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return (
    <section className="newpage-section">
      <Helmet>
        <title>Blogs Published by Admin - BloggerSpace Reviewer Panel</title>
      </Helmet>
      <Container>
        <h2 className="page-title">Admin Blogs</h2>
        <div className="heading-underline"></div>

        <Tabs
          defaultActiveKey="draft"
          id="justify-tab-example"
          className="mb-3"
          justify
        >
          <Tab
            eventKey="draft"
            title={
              <React.Fragment>
                Draft
                <Badge variant="light" className="mx-1">
                  {draftBlogs?.length}
                </Badge>
              </React.Fragment>
            }
          >
            <h5 className="mt-4">
              <b>Draft:</b>
            </h5>
            {draftBlogs === null ? (
              <PreLoader isLoading={true} />
            ) : draftBlogs?.length === 0 ? (
              <div>No draft blogs found</div>
            ) : (
              <>
                <ListGroup>
                  {draftBlogs?.map((blog) => (
                    <ListGroup.Item key={blog.slug}>
                      <div className="row align-items-center">
                        <div className="col">
                          <b>
                            {++i}. {blog.title}{" "}
                          </b>
                          <i
                            className="fas fa-eye"
                            onClick={() => handlePreviewBlogModal(blog._id)}
                          ></i>
                        </div>

                        <div className="col-auto">
                          <Link
                            to={`/admin/editblog/${blog._id}`}
                            //   target="_blank"
                            //   rel="noopener noreferrer"
                          >
                            <Button className="bs-button">Edit</Button>
                          </Link>
                        </div>

                        <div className="col-auto">
                          <Button
                            variant="danger"
                            size="sm"
                            className="m-2"
                            onClick={() =>
                              handleDiscardBlog(blog._id, blog.slug)
                            }
                          >
                            Discard
                          </Button>
                        </div>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </>
            )}
          </Tab>

          <Tab
            eventKey="published"
            title={
              <React.Fragment>
                Published
                <Badge variant="light" className="mx-1">
                  {publishedBlogs?.length}
                </Badge>
              </React.Fragment>
            }
          >
            <h5 className="mt-4">
              <b>Published by Admin:</b>
            </h5>
            {publishedBlogs === null ? (
              <PreLoader isLoading={true} />
            ) : publishedBlogs?.length === 0 ? (
              <div>No Published blogs found</div>
            ) : (
              <>
                <ListGroup>
                  {publishedBlogs?.map((blog) => (
                    <ListGroup.Item key={blog.slug}>
                      <div className="row align-items-center">
                        <div className="col">
                          <b>
                            {++j}. {blog.title}{" "}
                          </b>
                          <i
                            className="fas fa-eye"
                            onClick={() => handlePreviewBlogModal(blog._id)}
                          ></i>
                        </div>

                        <div className="col-auto">
                          <Link
                            to={`/admin/editblog/${blog._id}`}
                            //   target="_blank"
                            //   rel="noopener noreferrer"
                          >
                            <Button className="bs-button" size="sm">
                              Edit
                            </Button>
                          </Link>
                        </div>
                        <div className="col-auto">
                          <Button
                            variant="danger"
                            size="sm"
                            className="m-2"
                            onClick={() =>
                              handleDiscardBlog(blog._id, blog.slug)
                            }
                          >
                            Discard
                          </Button>
                        </div>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </>
            )}
          </Tab>

          <Tab
            eventKey="discarded"
            title={
              <React.Fragment>
                Discarded Blogs
                <Badge variant="light" className="mx-1">
                  {discardedBlogs?.length}
                </Badge>
              </React.Fragment>
            }
          >
            {/* In Review */}
            <h5 className="mt-4">
              <b>Discarded Blogs:</b>
            </h5>
            {discardedBlogs === null ? (
              <PreLoader isLoading={true} />
            ) : discardedBlogs?.length === 0 ? (
              <div>No discarded blogs found</div>
            ) : (
              <>
                <ListGroup>
                  {discardedBlogs?.map((blog) => (
                    <ListGroup.Item key={blog.slug}>
                      <div className="row align-items-center">
                        <div className="col">
                          <b>
                            {++k}. {blog.title}{" "}
                          </b>
                          <i
                            className="fas fa-eye"
                            onClick={() => handlePreviewBlogModal(blog._id)}
                          ></i>
                        </div>
                        <div className="col-auto">
                          <Link
                            to={`/admin/editblog/${blog._id}`}
                            //   target="_blank"
                            //   rel="noopener noreferrer"
                          >
                            <Button className="bs-button" size="sm">
                              Re-publish
                            </Button>
                          </Link>
                        </div>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </>
            )}
          </Tab>
        </Tabs>

        <Modal
          show={showConfirmModal}
          onHide={() => setShowConfirmModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Preview Blog</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Title: {previewBlog?.title}
            <br />
            Slug: {previewBlog?.slug}
            <br />
            <br />
            Content: <br />
            <div dangerouslySetInnerHTML={{ __html: previewBlog?.content }} />
            <h6>Content size: {previewContentSize} KB</h6>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowConfirmModal(false)}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </section>
  );
};

export default AdminBlogs;
