import React, { useState } from "react";
import { Form, Button, Alert, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import axios from "axios";
import { Helmet } from "react-helmet";
import "./ForgotPasswordPage.css";
import { toast, ToastContainer } from "react-toastify";

function ForgotPasswordPage() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    // Create the request body
    const requestBody = {
      email: email,
    };

    // Send the forgot password request to the backend
    axios
      .post("/api/reviewer/forgotpassword", requestBody)
      .then((response) => {
        toast.success(response.data.message + " to: " + email);
        // setSuccess("Password reset email sent successfully");
        setLoading(false);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          toast.error(error.response.data.error);
        } else {
          toast.error("Failed to send password reset email");
        }
        setLoading(false);
      });
  };

  return (
    <div>
      <Helmet>
        <title>Forgot Password - BloggerSpace Reviewer Panel</title>
      </Helmet>
      <ToastContainer />
      <div className="forgot-password-page">
        <div className="container col-lg-7">
          <div className="row justify-content-center">
            <div className="col-md-6 col-sm-8">
              <div className="forgot-password-form">
                <h2 className="text-center mb-4">Forgot Password</h2>
                
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="email">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      value={email}
                      onChange={handleEmailChange}
                      required
                    />
                  </Form.Group>

                  <Button
                    variant="primary"
                    type="submit"
                    className="forgot-password-button"
                    block
                    disabled={loading}
                  >
                    {loading ? (
                      <Spinner animation="border" role="status" size="sm" />
                    ) : (
                      "Send Password Reset Link"
                    )}
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPasswordPage;
