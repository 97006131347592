import React, { useState, useEffect,useContext } from "react";
import {
  Container,
  Card,
  Button,
  Alert,
  Modal,
  Spinner,
} from "react-bootstrap";
import axios from "axios";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./Settings.css";

import { AuthContext } from "contexts/AuthContext";
import PreLoader from "utils/PreLoader";
import { toast, ToastContainer } from "react-toastify";

const Settings = () => {
  const { user, loading, logout } = useContext(AuthContext);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const navigate = useNavigate();
  const location= useLocation();

  const handleDeleteAccount = () => {
    setShowConfirmModal(true); // Show the confirmation modal
  };

  const handleConfirmDelete = () => {
    // Remove the token from localStorage
    localStorage.removeItem("currenttoken");
    axios
      .delete("/api/reviewer/account/delete")
      .then((response) => {
        console.log(response.data.message);
        setShowConfirmModal(false);
        toast.success("Account deleted!!")
        setTimeout(() => {
          navigate("/");
        }, 2000); // Navigate to the home page after 2 seconds
      })
      .catch((error) => {
        console.error("Account deletion failed:", error);
        setShowConfirmModal(false);
        toast.error("Account deletion failed!");
      });
  };

  if (loading) {
    return (
      <PreLoader isLoading={loading} />
    );
  }

  if(!user && !loading){
    logout();
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return (
    <section className="newpage-section">
      <Helmet>
        <title>Settings - BloggerSpace Reviewer Panel</title>
      </Helmet>
<Container>
      <h2 className="page-title">Settings</h2>
      <div className="heading-underline"></div>
      <ToastContainer />
      <Card>
        <Card.Body>
          <Card.Title>Account Details </Card.Title>
          {user && (
            <>
              <p>
                <strong>Name:</strong> {user.fullName}
              </p>
              <p>
                <strong>Email:</strong> {user.email}
              </p>
              <p>
                <strong>Role:</strong> {user.role}
              </p>
              <div className="verification-status">
                <strong>Verification Status:</strong>{" "}
                {user.isVerified ? "Verified" : "Not Verified"}
              </div>
            </>
          )}
        </Card.Body>
      </Card>

      <Card className="mt-4">
        <Card.Body>
          <Card.Title>Account Actions</Card.Title>
          <Button
            variant="danger"
            onClick={handleDeleteAccount}
            disabled={user?.role === "Admin"}
          >
            Delete Account
          </Button>
          {user?.role === "Admin" && (
            <p>
              <b>Disabled for Admin</b>
            </p>
          )}
        </Card.Body>
      </Card>

      {/* Confirmation Modal */}
      <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete your account?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowConfirmModal(false)}
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
    </section>
  );
};

export default Settings;
