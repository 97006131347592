import React, { useState } from "react";
import {
  Form,
  Button,
  Alert,
  Container,
  Row,
  Col,
  FloatingLabel,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "./SignupPage.css";

import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { toast, ToastContainer } from "react-toastify";
import { CiLock } from "react-icons/ci";

function SignupPage() {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [role, setRole] = useState("Reviewer");
  const [signupHeading, setSignupHeading] = useState("Reviewer");

  const navigate = useNavigate();

  const handleFullNameChange = (e) => {
    setFullName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleRoleChange = (e) => {
    setRole(e.target.value);
    setSignupHeading(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    // Password complexity validation
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/;
    if (!passwordRegex.test(password)) {
      toast.error(
        "Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one digit, and one special character"
      );
      return;
    }

    // Create the request body
    const requestBody = {
      fullName: fullName,
      email: email,
      password: password,
    };
    console.log(requestBody);

    if (role === "Reviewer") {
      axios
        .post("/api/reviewer/signup", requestBody)
        .then((response) => {
          // Handle the signup response here
          console.log(response.data);

          // Set the signup success flag
          toast.success(
            "Signup successful! Please check the mail received on your given email."
          );

          // Clear form fields
          setFullName("");
          setEmail("");
          setPassword("");
          setConfirmPassword("");

          // Redirect to the homepage after a delay
          setTimeout(() => {
            navigate("/");
          }, 1000);
        })
        .catch((error) => {
          // Handle the error response here
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            toast.error(error.response.data.message);
          } else {
            // setError("Signup failed");
            toast.error(error);
            console.log(error);
          }
        });
    } else {
      // Send the signup request to the backend
      axios
        .post("/api/admin/signup", requestBody)
        .then((response) => {
          // Handle the signup response here
          console.log(response.data);

          // Set the signup success flag
          toast.success(
            "Signup successful! Please check the mail received on your given email."
          );

          // Clear form fields
          setFullName("");
          setEmail("");
          setPassword("");
          setConfirmPassword("");

          // Redirect to the homepage after a delay
          setTimeout(() => {
            navigate("/");
          }, 1000);
        })
        .catch((error) => {
          // Handle the error response here
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            toast.error(error.response.data.message);
          } else {
            // setError("Signup failed");
            toast.error(error);
            console.log(error);
          }
        });
    }
  };

  return (
    <section className="newpage-section">
      <Helmet>
        <title>Sign up - BloggerSpace Reviewer Panel</title>
      </Helmet>
      <ToastContainer />
      <Container>
        <Row className="pt-3">
          <Col md={6}>
            {/* <img
              src="assets/signup.png"
              // src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9bb9Mz7yTmUO-Ky9T9pTXHb2W5cUW9_L4FWcxCyGq5A&s"
              className="loginpage-image"
            /> */}
            <CiLock className="lock-icon" />
          </Col>
          <Col md={6}>
            <div className="signup-form">
              <h2 className="text-center loginpage-heading">
                {signupHeading} Sign Up
              </h2>
              <div className="underline mx-auto"></div>

              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="fullName">
                  <FloatingLabel
                    controlId="floatingInput1"
                    label="Enter your Full Name"
                    className="mb-3"
                    key="fullName"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Enter your full name"
                      value={fullName}
                      onChange={handleFullNameChange}
                      required
                    />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group controlId="email">
                  <FloatingLabel
                    controlId="floatingInput2"
                    label="Enter Email"
                    className="mb-3"
                    key="email"
                  >
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      value={email}
                      onChange={handleEmailChange}
                      required
                    />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group controlId="password">
                  <div className="password-input">
                    <FloatingLabel
                      controlId="floatingInput3"
                      label="Enter Password"
                      className="mb-3"
                      key="password"
                    >
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        value={password}
                        onChange={handlePasswordChange}
                        required
                      />
                    </FloatingLabel>
                    <i
                      className={`toggle-password fas ${
                        showPassword ? "fa-eye-slash" : "fa-eye"
                      }`}
                      onClick={togglePasswordVisibility}
                    ></i>
                  </div>
                </Form.Group>

                <Form.Group controlId="confirmPassword">
                  <div className="confirmpassword-input">
                <FloatingLabel
                      controlId="floatingInput4"
                      label="Confirm Password"
                      className="mb-3"
                      key="confirmPassword"
                    >
                    <Form.Control
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder="Confirm Password"
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                      required
                    />
                    </FloatingLabel>
                    <i
                      className={`toggle-confirmpassword fas ${
                        showConfirmPassword ? "fa-eye-slash" : "fa-eye"
                      }`}
                      onClick={toggleConfirmPasswordVisibility}
                    ></i>
                  </div>
                </Form.Group>

                <Form.Group controlId="role">
                  {" "}
                  <FloatingLabel
                      controlId="floatingInput5"
                      label="Role"
                      className="mb-3"
                      key="role"
                    >
                  <Form.Control
                    as="select"
                    value={role}
                    onChange={handleRoleChange}
                  >
                    <option value="Reviewer">Reviewer</option>
                    <option value="Admin" disabled>
                      Admin
                    </option>
                  </Form.Control>
                  </FloatingLabel>
                </Form.Group>

                <Button
                  variant="primary"
                  type="submit"
                  className="mt-2 bs-button"
                >
                  Sign up
                </Button>

                <div>
                  Already have an account? <Link to="/login">Login</Link>
                </div>
              </Form>
            </div>{" "}
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default SignupPage;
