import React, { useState, useEffect, useContext } from "react";
import { Container, Card, ListGroup, Spinner } from "react-bootstrap";
import { Link, Navigate, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import "./ReviewedBlogs.css";

import { AuthContext } from "contexts/AuthContext";
import PreLoader from "utils/PreLoader";

const ReviewedBlogs = () => {
  const { user, loading, logout } = useContext(AuthContext);
  let i = 0;

  const location= useLocation();

  if (loading) {
    return <PreLoader isLoading={loading} />;
  }

  if(!user && !loading){
    logout();
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  const { fullName, email, reviewedBlogs } = user;

  return (
    <section className="newpage-section">
      <Helmet>
        <title>Reviewed Blogs - BloggerSpace Reviewer Panel</title>
      </Helmet>
      <Container>
        <h2 className="page-title">Reviewed Blogs</h2>
        <div className="heading-underline"></div>

        <Card>
          <Card.Body>
            <Card.Title>{fullName}</Card.Title>
            <Card.Text>Email: {email}</Card.Text>
          </Card.Body>
        </Card>

        <h5 className="mt-4">
          <b>Reviewed blogs:</b>
        </h5>
        {reviewedBlogs.length === 0 ? (
          <div>No blogs found</div>
        ) : (
          <ListGroup>
            {reviewedBlogs.map((blog) => (
              <ListGroup.Item key={blog.BlogSlug}>
                {++i}. {blog.BlogTitle}
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </Container>
    </section>
  );
};

export default ReviewedBlogs;
