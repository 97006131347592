import React, { useEffect, useState, useContext } from "react";
import {
  Container,
  Card,
  Spinner,
  Button,
  Form,
  ListGroup,
} from "react-bootstrap";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import "./InReview.css";

import { AuthContext } from "contexts/AuthContext";
import PreLoader from "utils/PreLoader";


function InReview() {
  const { user, loading, logout } = useContext(AuthContext);
  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const location= useLocation();

  useEffect(() => {
    const fetchInReviewBlogs = async () => {
      try {
        const response = await axios.get(`/api/admin/inreviewblogs?userId=${user._id}&role=${user.role}`);
        setBlogs(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching pending blogs:", error);
      }
    };

    fetchInReviewBlogs();
  }, [user]);

  if (isLoading) {
    return (
      <PreLoader isLoading={isLoading} />
    );
  }

  if (blogs.length === 0) {
    return (
      <Container className="d-flex justify-content-center align-items-center vh-100">
        <div>No pending blogs found.</div>
      </Container>
    );
  }
  var i = 0;

  const getBlogReviewedBy = (reviewedby) => {
    let res = [];
    for (let index = 0; index < reviewedby.length; index++) {
      res.push(
        reviewedby[index]["ReviewedBy"]["Email"] +
          " (Rating:" +
          reviewedby[index]["Rating"] +
          ")"
      );
    }
    return res.join(", ");
  };

  const getOverallRating = (reviewedby) => {
    let sum = 0;
    for (let index = 0; index < reviewedby.length; index++) {
      sum += reviewedby[index]["Rating"];
    }
    return sum / reviewedby.length;
  };

  if(!user && !loading){
    logout();
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return (
    <section className="newpage-section">
      <Helmet>
        <title>In Review Blogs - BloggerSpace Reviewer Panel</title>
      </Helmet>
      <Container>
        <h2 className="page-title">In Review Blogs</h2>
        <div className="heading-underline"></div>

        {blogs?.length === 0 ? (
          <div>No In Review blogs found</div>
        ) : (
          <>
            <ListGroup>
              {blogs?.map((blog) => (
                <ListGroup.Item key={blog.slug}>
                  <div className="row align-items-center">
                    <div className="col">
                      <b>
                        {++i}. {blog.title}{" "}
                      </b>
                      <p>
                        <i>author: {blog.authorEmail}</i> <br />
                        {/* <i>Reviewed By: {blog.reviewedBy.join(", ")}</i> */}
                        <i>
                          Reviewed By: {getBlogReviewedBy(blog.reviewedBy)}
                        </i>{" "}
                        <br />
                        <i>Rating: {getOverallRating(blog.reviewedBy)}</i>
                      </p>
                    </div>

                    <div className="col-auto">
                      <Link
                        to={`/admin/blog/editblog/${blog._id}`}
                        //   target="_blank"
                        //   rel="noopener noreferrer"
                      >
                        <Button variant="primary" className="bs-button">Review</Button>
                      </Link>
                    </div>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </>
        )}
      </Container>
    </section>
  );
}

export default InReview;
